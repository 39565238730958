import {
  Box,
  Button,
  CardProps,
  Checkbox,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { FileAPI } from '#/types/digitalAssets';
import useBoolean from '#/lib/hooks/useBoolean';
import Iconify from '#/components/shared/ui/Iconify';
import FileThumbnail from '#/components/shared/file-thumbnail';
import TextMaxLine from '#/components/shared/text-max-line/TextMaxLine';
import { fData } from '#/utils/formatNumber';
import { fDateTime } from '#/utils/formatTime';
import usePopover from '#/lib/hooks/usePopover';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import useLocales from '#/lib/hooks/useLocales';
import {
  invalidateDigitalAssetsQueries,
  useRenameFile,
  useToggleFavoriteFile,
} from '#/lib/api/digitalAssets';
import FileManagerNewFolderDialog from '#/components/shared/file-manager/file-manager-new-folder-dialog';
import ConfirmDialog from '#/components/shared/confirm-dialog';
import IconButtonAnimate from '#/components/shared/ui/IconButtonAnimate';
import FileManagerDetails from '#/components/shared/file-manager/file-manager-details';

interface Props extends CardProps {
  file: FileAPI;
  selected?: boolean;
  onSelect?: VoidFunction;
  onDelete: VoidFunction;
  onOpen?: VoidFunction;
  entityType: 'folder' | 'file';
}

export default function GridFileItem({
  file,
  selected,
  onSelect,
  onDelete,
  onOpen,
  sx,
  entityType,
  ...other
}: Props) {
  const [fileName, setFileName] = useState(
    file.filename.substring(0, file.filename.lastIndexOf('.'))
  );

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: file.id.toString(),
      data: { type: entityType },
    });

  const { translate } = useLocales();
  const checkbox = useBoolean();
  const popover = usePopover();
  const editFile = useBoolean();
  const favorite = useBoolean(file.starred);
  const details = useBoolean();

  const { mutateAsync: updateFile } = useRenameFile(file.id);
  const { mutateAsync: toggleFavorite } = useToggleFavoriteFile(file.id);

  const confirm = useBoolean();

  const handleChangeFileName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFileName(event.target.value);
    },
    []
  );

  const handleToggleFavorite = async () => {
    try {
      // @ts-ignore
      await toggleFavorite({ starred: !favorite.value });
      invalidateDigitalAssetsQueries.getFiles();
    } catch (error) {
      console.error('Error toggling favorite: ');
    }
  };

  const handleFileRename = async (name: string) => {
    try {
      await updateFile({ name });
      invalidateDigitalAssetsQueries.getFiles();
      editFile.onFalse();
    } catch (error) {
      console.error('Error updating folder: ');
    }
  };

  const renderIcon =
    (checkbox.value || selected) && onSelect ? (
      <Checkbox
        size="medium"
        checked={selected}
        onClick={onSelect}
        icon={<Iconify icon="eva:radio-button-off-fill" />}
        checkedIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}
        sx={{ p: 0.75 }}
      />
    ) : (
      <FileThumbnail
        file={file.content_type.split('/')[1]}
        sx={{
          width: 36,
          height: 36,
          cursor: 'pointer',
        }}
      />
    );

  const renderAction = (
    <Stack direction="row" alignItems="center">
      <Checkbox
        color="warning"
        icon={<Iconify icon="eva:star-outline" />}
        checkedIcon={<Iconify icon="eva:star-fill" />}
        checked={file.starred || favorite.value}
        onChange={() => {
          handleToggleFavorite();
          favorite.onToggle();
        }}
      />

      <IconButton
        color={popover.open ? 'inherit' : 'default'}
        onClick={popover.onOpen}
      >
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
    </Stack>
  );

  const renderDragIcon = (
    <IconButtonAnimate
      sx={{
        cursor: 'grab',
      }}
      {...listeners}
      {...attributes}
    >
      <Iconify icon="mdi:drag" />
    </IconButtonAnimate>
  );

  const renderText = (
    <>
      <TextMaxLine
        persistent
        variant="subtitle2"
        onClick={onOpen}
        sx={{
          width: 1,
          mt: 2,
          mb: 0.5,
          cursor: 'pointer',
        }}
      >
        {file.filename}
      </TextMaxLine>

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          maxWidth: 0.99,
          whiteSpace: 'nowrap',
          typography: 'caption',
          color: 'text.disabled',
        }}
      >
        {fData(file.size)}

        <Box
          component="span"
          sx={{
            mx: 0.75,
            width: 2,
            height: 2,
            flexShrink: 0,
            borderRadius: '50%',
            bgcolor: 'currentColor',
          }}
        />
        <Typography noWrap component="span" variant="caption">
          {fDateTime(file.uploaded_at)}
        </Typography>
      </Stack>
    </>
  );

  const defaultStyle: React.CSSProperties = {
    transition: 'transform 200ms ease',
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    // cursor: 'grab',
    userSelect: 'none',
    padding: '8px',
    // border: '1px solid #ccc',
    borderRadius: '8px',
    // backgroundColor: '#f9f9f9',
  };

  return (
    <>
      <Stack
        ref={setNodeRef}
        component={Paper}
        variant="outlined"
        alignItems="flex-start"
        sx={{
          ...defaultStyle,
          bgcolor: (theme) => theme.palette.background.paper,
          p: 2.5,
          borderRadius: 2,
          position: 'relative',
          ...((checkbox.value || selected) && {
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z20,
          }),
          ...sx,
        }}
        {...other}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          {renderDragIcon}
          {renderAction}
        </Stack>
        <Box onClick={onOpen}>{renderIcon}</Box>
        {renderText}
      </Stack>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            editFile.onTrue();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {String(translate('global.edit'))}
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {String(translate('global.delete'))}
        </MenuItem>
      </CustomPopover>
      <FileManagerDetails
        item={file}
        open={details.value}
        onClose={details.onFalse}
      />
      <FileManagerNewFolderDialog
        open={editFile.value}
        onClose={editFile.onFalse}
        title={String(translate('global.editFile'))}
        onUpdate={() => handleFileRename(fileName)}
        onChangeFolderName={handleChangeFileName}
        folderName={fileName}
        inputLabel={String(translate('global.fileName'))}
      />
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={String(translate('global.deleteConfirmation.title'))}
        content={String(
          translate('global.deleteConfirmation.question', {
            item: file.filename,
          })
        )}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDelete();
              confirm.onFalse();
            }}
          >
            <Typography>{String(translate('global.delete'))}</Typography>
          </Button>
        }
      />
    </>
  );
}
