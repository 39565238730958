import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  invalidateSuccessorQueries,
  useGetInvitationsList,
  useUpdateInvitation,
} from '#/lib/api/successor';
import useLocales from '#/lib/hooks/useLocales';
import { Successor } from '#/types/user';

type Props = {
  onClose: () => void;
};

export default function AddNewItem({ onClose }: Props) {
  const [selectedSuccessor, setSelectedSuccessor] = useState<string>();
  const [selectedAction, setSelectedAction] = useState<string>('view');

  const { translate } = useLocales();

  const { data: successors } = useGetInvitationsList();

  const { mutateAsync: updateInvitationRights } =
    useUpdateInvitation(selectedSuccessor);

  const handleUpdateInvitationRights = async () => {
    try {
      const successor = successors?.find(
        (successor) => successor.unique_id === selectedSuccessor
      ) as Successor;

      await updateInvitationRights({
        ...successor,
        specific_service_access: [
          ...(successor?.specific_service_access || []),
          { service: 'tax', action: 'view', accessLevel: 'while-alive' },
        ],
      });
      invalidateSuccessorQueries.getInvitationsList();
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <FormControl fullWidth>
        <InputLabel
          sx={{
            color: 'text.secondary',
          }}
        >
          {translate('global.userSettings.successors')}
        </InputLabel>
        <Select
          value={selectedSuccessor}
          onChange={(e) => setSelectedSuccessor(e.target.value)}
          label={translate('global.userSettings.successors')}
        >
          {successors?.map((successor) => (
            <MenuItem key={successor.unique_id} value={successor.unique_id}>
              {successor.first_name} {successor.last_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel
          sx={{
            color: 'text.secondary',
          }}
        >
          {translate('global.access')}
        </InputLabel>
        <Select
          value={selectedAction}
          onChange={(e) => setSelectedAction(e.target.value)}
          label={translate('global.access')}
        >
          <MenuItem value="view">{translate('global.view')}</MenuItem>
          <MenuItem value="edit">
            <Typography>{translate('global.edit')}</Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Stack direction="row" spacing={2}>
        <Button variant="outlined" onClick={onClose}>
          <Typography>{translate('global.cancel')}</Typography>
        </Button>
        <Button variant="contained" onClick={handleUpdateInvitationRights}>
          <Typography>{translate('global.save')}</Typography>
        </Button>
      </Stack>
    </Stack>
  );
}
