import { Box, IconButton, Stack, StackProps, Typography } from '@mui/material';
import Iconify from '../ui/Iconify';

interface Props extends StackProps {
  title: string;
  subTitle?: string;
  link?: string;
  onOpen?: VoidFunction;
  collapse?: boolean;
  onCollapse?: VoidFunction;
  hideButton?: boolean;
}

export default function FileManagerPanel({
  title,
  subTitle,
  onOpen,
  sx,
  hideButton,
  ...other
}: Props) {
  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 3, ...sx }} {...other}>
      <Stack flexGrow={1}>
        <Stack direction="row" alignItems="center" spacing={1} flexGrow={1}>
          <Typography variant="h6"> {title} </Typography>
          {!hideButton && (
            <IconButton
              size="small"
              color="primary"
              onClick={onOpen}
              sx={{
                width: 24,
                height: 24,
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              <Iconify icon="mingcute:add-line" />
            </IconButton>
          )}
        </Stack>
        <Box sx={{ typography: 'body2', color: 'text.disabled', mt: 0.5 }}>
          {subTitle}
        </Box>
      </Stack>
    </Stack>
  );
}
