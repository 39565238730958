import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { TableProps } from '#/types/elements';
import FileTableRow from './table-row';
import TableHeadCustom from '#/components/shared/table/table-head-custom';
import { FileAPI, FilesAPI, FolderAPI } from '#/types/digitalAssets';
import {
  invalidateDigitalAssetsQueries,
  useCreateFolder,
  useDeleteFile,
  useDeleteFolder,
} from '#/lib/api/digitalAssets';
import FileManagerNewFolderDialog from '#/components/shared/file-manager/file-manager-new-folder-dialog';
import useLocales from '#/lib/hooks/useLocales';
import useBoolean from '#/lib/hooks/useBoolean';
import FileManagerPanel from '#/components/shared/file-manager/file-manager-panel';
import TableNoData from '#/components/shared/table/tabel-empty-rows';
import Iconify from '#/components/shared/ui/Iconify';
import TableSelectedAction from '#/components/shared/table/table-selected-action';
import FileManagerDetails from '#/components/shared/file-manager/file-manager-details';

type Props = {
  table: TableProps;
  dataFiltered?: FilesAPI;
  openFolder: (id: string, name: string) => void;
  openFile: (filepath: string) => void;
  onOpenConfirm: VoidFunction;
  parentList: any[];
  notFound: boolean;
  service?: string;
};

const TABLE_HEAD = [
  { id: '', width: 88 },
  { id: '', width: 88 },

  { id: 'name', label: 'global.fileSystemTableHead.name' },
  { id: 'size', label: 'global.fileSystemTableHead.size', width: 120 },
  { id: 'type', label: 'global.fileSystemTableHead.type', width: 120 },
  {
    id: 'modifiedAt',
    label: 'global.fileSystemTableHead.modifiedAt',
    width: 140,
  },
];

export default function TableView({
  table,
  dataFiltered,
  openFolder,
  openFile,
  onOpenConfirm,
  parentList,
  notFound,
  service,
}: Props) {
  const [downloadUrls, setDownloadUrls] = useState<string[]>([]);
  const [folderName, setFolderName] = useState('');

  const { translate } = useLocales();
  const theme = useTheme();

  const { mutateAsync: createFolder, isLoading: creatingFolder } =
    useCreateFolder();

  const { mutateAsync: onFolderDelete } = useDeleteFolder();
  const { mutateAsync: onFileDelete } = useDeleteFile();

  const { selected, onSelectRow, onSelectAllRows, dense } = table;

  const newFolder = useBoolean();
  const upload = useBoolean();
  const details = useBoolean();

  const isTaxFolder = service === 'tax';
  const hideInsertions = isTaxFolder && parentList.length <= 0;
  const disableDrag = isTaxFolder && parentList.length < 2;

  const handleCollectDownloadUrls = (url: string) => {
    setDownloadUrls((prev) => [...prev, url]);
  };

  const handleDownloadFiles = useCallback(() => {
    if (!downloadUrls.length) {
      return;
    }

    downloadUrls.forEach((url) => {
      try {
        window.open(url, '_blank');
      } catch (error) {
        console.error('Failed to download file:', error);
      }
    });

    setDownloadUrls([]); // Clear URLs after download
  }, [downloadUrls]);

  const handleChangeFolderName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFolderName(event.target.value);
    },
    []
  );

  const handleFolderCreate = async () => {
    try {
      const parent = parentList[parentList.length - 1];
      await createFolder({
        name: folderName,
        parent: parentList.length > 0 ? parent.id : null,
        ...(service && { service }),
      }).then(() => {
        newFolder.onFalse();
        invalidateDigitalAssetsQueries.getFiles();
        setFolderName('');
      });
    } catch (error) {
      console.error('Error creating folder: ');
    }
  };

  const handleOpenFile = (row: any, file?: string) => {
    if (!file) openFolder(row?.id, row.name);
    else openFile(file);
  };

  const handleFileDelete = async (id: string) => {
    try {
      await onFileDelete({ id });
      invalidateDigitalAssetsQueries.getFiles();
    } catch (error) {
      console.error('Error deleting file: ');
    }
  };

  const handleFolderDelete = async (id: string) => {
    try {
      await onFolderDelete({ id });
      invalidateDigitalAssetsQueries.getFiles();
    } catch (error) {
      console.error('Error deleting folder: ');
    }
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <FileManagerPanel
            title={translate('global.folder')}
            subTitle={`${dataFiltered?.folders?.length} ${translate(
              'global.folders'
            )}`}
            onOpen={newFolder.onTrue}
            hideButton={hideInsertions}
          />
          <FileManagerPanel
            title={translate('global.file')}
            subTitle={`${dataFiltered?.files?.length} ${translate(
              'global.files'
            )}`}
            onOpen={upload.onTrue}
            hideButton={hideInsertions}
          />
        </Stack>
        {disableDrag && hideInsertions && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle1">
              {translate('global.shareWith')}
            </Typography>
            <AvatarGroup>
              <Avatar src="/assets/images/avatars/avatar-default.jpg" />
              <Avatar src="/assets/images/avatars/avatar-default.jpg" />
            </AvatarGroup>
            <IconButton
              size="small"
              color="primary"
              onClick={details.onTrue}
              sx={{
                width: 24,
                height: 24,
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              <Iconify icon="mingcute:add-line" />
            </IconButton>
          </Stack>
        )}
      </Stack>

      <FileManagerDetails open={details.value} onClose={details.onFalse} />

      <FileManagerNewFolderDialog
        open={newFolder.value}
        onClose={newFolder.onFalse}
        title={String(translate('global.newFolder'))}
        onChangeFolderName={handleChangeFolderName}
        onCreate={handleFolderCreate}
        isLoading={creatingFolder}
      />
      <FileManagerNewFolderDialog
        open={upload.value}
        onClose={() => {
          upload.onFalse();
        }}
        parent={
          parentList.length > 0 ? parentList[parentList.length - 1].id : null
        }
        title={String(translate('global.uploadFiles'))}
      />
      <Box
        sx={{
          position: 'relative',
          m: theme.spacing(-2, -3, -3, -3),
        }}
      >
        <TableSelectedAction
          dense={dense}
          numSelected={selected.length}
          rowCount={
            (dataFiltered?.folders?.length ?? 0) +
            (dataFiltered?.files?.length ?? 0)
          }
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              [
                ...(dataFiltered?.folders || []),
                ...(dataFiltered?.files || []),
              ].map((row) => row.id) as string[]
            )
          }
          action={
            <Stack direction="row" alignItems="center" spacing={2}>
              <Tooltip title={translate('global.download')}>
                <IconButton color="primary" onClick={handleDownloadFiles}>
                  <Iconify icon="mingcute:download-3-fill" />
                </IconButton>
              </Tooltip>
              <Tooltip title={translate('global.delete')}>
                <IconButton color="primary" onClick={onOpenConfirm}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            </Stack>
          }
          sx={{
            marginBottom: 3,
            top: 16,
            borderRadius: 1.5,
          }}
        />
        <TableContainer
          sx={{
            p: theme.spacing(0, 3, 3, 3),
          }}
        />
        <Table
          size="small"
          sx={{
            minWidth: 960,
            borderCollapse: 'separate',
            borderSpacing: '0 16px',
          }}
        >
          <TableHeadCustom headLabel={TABLE_HEAD} />
          <TableBody>
            {dataFiltered?.folders?.map((row) => (
              <FileTableRow
                key={`folder-${row.id}`}
                row={row as FolderAPI & FileAPI}
                selected={selected.includes(String(row.id))}
                onSelectRow={() => onSelectRow(String(row.id))}
                onDeleteRow={() => handleFolderDelete(String(row.id))}
                onOpen={() => handleOpenFile(row, row?.url ?? '')}
                entityType="folder"
                disableDrag={disableDrag}
              />
            ))}
            {dataFiltered?.files?.map((row) => (
              <FileTableRow
                key={`file-${row.id}`}
                row={row as FolderAPI & FileAPI}
                selected={selected.includes(String(row.id))}
                onSelectRow={() => {
                  onSelectRow(String(row.id));
                  handleCollectDownloadUrls(row.download_url);
                }}
                onDeleteRow={() => handleFileDelete(String(row.id))}
                onOpen={() => handleOpenFile(row, row?.download_url)}
                entityType="file"
                disableDrag={disableDrag}
              />
            ))}
          </TableBody>
          <TableNoData notFound={notFound} />
        </Table>
      </Box>
    </>
  );
}
