import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import GridFolderItem from './grid-folder-item';
import FileManagerPanel from '#/components/shared/file-manager/file-manager-panel';
import { TableProps } from '#/types/elements';
import { FilesAPI } from '#/types/digitalAssets';
import useBoolean from '#/lib/hooks/useBoolean';
import GridFileItem from './grid-file-item';
import FileManagerNewFolderDialog from '#/components/shared/file-manager/file-manager-new-folder-dialog';
import useLocales from '#/lib/hooks/useLocales';

import {
  invalidateDigitalAssetsQueries,
  useCreateFolder,
  useDeleteFile,
  useDeleteFolder,
} from '#/lib/api/digitalAssets';
import { DroppableContainer } from '#/components/shared/dnd/droppable-container';
import EmptyContent from '#/components/shared/EmptyContent';
import Iconify from '#/components/shared/ui/Iconify';
import FileManagerDetails from '#/components/shared/file-manager/file-manager-details';

type Props = {
  table: TableProps;
  dataFiltered?: FilesAPI;
  openFolder: (id: string, name: string) => void;
  openFile: (filepath: string) => void;
  parentList: any[];
  notFound: boolean;
  service?: string;
};

export default function GridView({
  table,
  dataFiltered,

  openFolder,
  openFile,
  parentList,
  notFound,
  service,
}: Props) {
  const [folderName, setFolderName] = useState('');

  const { mutateAsync } = useCreateFolder();
  const { mutateAsync: onFileDelete } = useDeleteFile();
  const { mutateAsync: onFolderDelete } = useDeleteFolder();

  const isTaxFolder = service === 'tax';
  const hideInsertions = isTaxFolder && parentList.length <= 0;
  const disableDrag = isTaxFolder && parentList.length < 2;

  const handleChangeFolderName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFolderName(event.target.value);
    },
    []
  );

  const handleFolderCreate = async () => {
    try {
      const parent = parentList[parentList.length - 1];
      await mutateAsync({
        name: folderName,
        parent: parentList.length > 0 ? parent.id : null,
        ...(service && { service }),
      }).then(() => {
        newFolder.onFalse();
        invalidateDigitalAssetsQueries.getFiles();
        setFolderName('');
      });
    } catch (error) {
      console.error('Error creating folder: ');
    }
  };

  const handleFileDelete = async (id: string) => {
    try {
      await onFileDelete({ id });
      invalidateDigitalAssetsQueries.getFiles();
    } catch (error) {
      console.error('Error deleting file: ');
    }
  };

  const handleFolderDelete = async (id: string) => {
    try {
      await onFolderDelete({ id });
      invalidateDigitalAssetsQueries.getFiles();
    } catch (error) {
      console.error('Error deleting folder: ');
    }
  };

  const { selected, onSelectRow: onSelectItem } = table;

  const { translate } = useLocales();
  const newFolder = useBoolean();
  const folders = useBoolean();
  const files = useBoolean();
  const upload = useBoolean();
  const details = useBoolean();

  const containerRef = useRef(null);

  return (
    <>
      <Box ref={containerRef}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          justifyContent="space-between"
        >
          <FileManagerPanel
            title={translate('global.folder')}
            subTitle={`${dataFiltered?.folders?.length} ${translate(
              'global.folders'
            )}`}
            onOpen={newFolder.onTrue}
            collapse={folders.value}
            onCollapse={folders.onToggle}
            hideButton={hideInsertions}
          />
          {disableDrag && hideInsertions && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="subtitle1">
                {translate('global.shareWith')}
              </Typography>
              <AvatarGroup>
                <Avatar src="/assets/images/avatars/avatar-default.jpg" />
                <Avatar src="/assets/images/avatars/avatar-default.jpg" />
              </AvatarGroup>
              <IconButton
                size="small"
                color="primary"
                onClick={details.onTrue}
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }}
              >
                <Iconify icon="mingcute:add-line" />
              </IconButton>
            </Stack>
          )}
        </Stack>
        {dataFiltered?.folders?.length === 0 && notFound && (
          <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <EmptyContent description={translate('global.noData')} />
          </Box>
        )}
        <Box
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
        >
          {dataFiltered?.folders?.map((folder) => (
            <DroppableContainer key={folder.id} folder={folder}>
              <GridFolderItem
                key={folder.id}
                folder={folder}
                onSelect={() => onSelectItem(String(folder.id))}
                onDelete={() => handleFolderDelete(String(folder.id))}
                onOpen={() => openFolder(String(folder.id), folder.name)}
                sx={{ maxWidth: 'auto' }}
                entityType="folder"
                disableDrag={disableDrag}
              />
            </DroppableContainer>
          ))}
        </Box>
      </Box>
      <Divider sx={{ my: 5, borderStyle: 'dashed' }} />
      {!isTaxFolder && (
        <>
          <FileManagerPanel
            title={translate('global.file')}
            subTitle={`${dataFiltered?.files?.length} ${translate(
              'global.files'
            )}`}
            onOpen={upload.onTrue}
            collapse={files.value}
            onCollapse={files.onToggle}
          />
          {dataFiltered?.files?.length === 0 && notFound && (
            <Box sx={{ justifyContent: 'center', display: 'flex' }}>
              <EmptyContent description={translate('global.noData')} />
            </Box>
          )}
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
            }}
            gap={3}
          >
            {dataFiltered?.files?.map((file) => (
              <GridFileItem
                key={file.id}
                file={file}
                onOpen={() => openFile(file.download_url)}
                selected={selected.includes(String(file.id))}
                onSelect={() => onSelectItem(String(file.id))}
                onDelete={() => handleFileDelete(String(file.id))}
                sx={{
                  maxWidth: {
                    xs: 'auto',
                    lg: '270px',
                  },
                }}
                entityType="file"
              />
            ))}
          </Box>
        </>
      )}

      <FileManagerDetails open={details.value} onClose={details.onFalse} />

      <FileManagerNewFolderDialog
        open={upload.value}
        onClose={upload.onFalse}
        parent={
          parentList.length > 0 ? parentList[parentList.length - 1].id : null
        }
        title={String(translate('global.uploadFiles'))}
      />
      <FileManagerNewFolderDialog
        open={newFolder.value}
        onClose={newFolder.onFalse}
        title={String(translate('global.newFolder'))}
        onChangeFolderName={handleChangeFolderName}
        onCreate={handleFolderCreate}
      />
    </>
  );
}
