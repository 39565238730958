import Button from '@mui/material/Button';
// import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import { Typography } from '@mui/material';
import Iconify from '../ui/Iconify';
import CustomPopover from '../custom-popover/custom-popover';
import usePopover from '#/lib/hooks/usePopover';
import { Successor } from '#/types/user';
import useLocales from '#/lib/hooks/useLocales';
import {
  invalidateSuccessorQueries,
  useUpdateInvitation,
} from '#/lib/api/successor';

type Props = {
  person: Successor;
};

export default function FileManagerInvitedItem({ person }: Props) {
  const action = person.specific_service_access?.find(
    (item) => item.service === 'tax'
  )?.action;

  const popover = usePopover();
  const { translate } = useLocales();

  const { mutateAsync: updateInvitationRights } = useUpdateInvitation(
    person?.unique_id
  );

  const handleUpdateInvitationRights = async (action: string) => {
    try {
      await updateInvitationRights({
        ...person,
        specific_service_access: person.specific_service_access?.map((item) =>
          item.service === 'tax' ? { ...item, action } : item
        ),
      });
      invalidateSuccessorQueries.getInvitationsList();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteInvitation = async () => {
    try {
      await updateInvitationRights({
        ...person,
        specific_service_access: person.specific_service_access?.filter(
          (item) => item.service !== 'tax'
        ),
      });
      invalidateSuccessorQueries.getInvitationsList();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ListItem
        sx={{
          px: 0,
          py: 1,
        }}
      >
        {/* <Avatar alt={person.first_name} src={person.avatarUrl} sx={{ mr: 2 }} /> */}

        <ListItemText
          primary={`${person.first_name} ${person.last_name}`}
          secondary={
            <Tooltip title={person.email}>
              <span>{person.email}</span>
            </Tooltip>
          }
          primaryTypographyProps={{ noWrap: true, typography: 'subtitle2' }}
          secondaryTypographyProps={{ noWrap: true, component: 'span' }}
          sx={{ flexGrow: 1, pr: 1 }}
        />

        <Button
          size="small"
          color="inherit"
          endIcon={
            <Iconify
              width={20}
              icon={
                popover.open
                  ? 'eva:arrow-ios-upward-fill'
                  : 'eva:arrow-ios-downward-fill'
              }
              sx={{ ml: -1 }}
            />
          }
          onClick={popover.onOpen}
          sx={{
            flexShrink: 0,
            ...(popover.open && {
              bgcolor: 'action.selected',
            }),
          }}
        >
          <Typography>
            {translate(
              `global.can_${person.specific_service_access?.find(
                (item) => item.service === 'tax'
              )?.action}`
            )}
          </Typography>
        </Button>
      </ListItem>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 160 }}
      >
        <>
          <MenuItem
            selected={action === 'view'}
            onClick={() => {
              popover.onClose();
              handleUpdateInvitationRights('view');
            }}
          >
            <Iconify icon="solar:eye-bold" />
            <Typography>{translate('global.can_view')}</Typography>
          </MenuItem>

          <MenuItem
            selected={action === 'delete'}
            onClick={() => {
              popover.onClose();
              handleUpdateInvitationRights('edit');
            }}
          >
            <Iconify icon="solar:pen-bold" />
            <Typography>{translate('global.can_edit')}</Typography>
          </MenuItem>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <MenuItem
            onClick={handleDeleteInvitation}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            <Typography>{translate('global.delete')}</Typography>
          </MenuItem>
        </>
      </CustomPopover>
    </>
  );
}
