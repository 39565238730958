import {
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  MenuItem,
  Stack,
  TableCell,
  tableCellClasses,
  TableRow,
  tableRowClasses,
  Typography,
  useTheme,
} from '@mui/material';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useCallback, useState } from 'react';
import { useDoubleClick } from '#/lib/hooks/useDoubleClick';
import useBoolean from '#/lib/hooks/useBoolean';
import FileThumbnail from '#/components/shared/file-thumbnail';
import { fData } from '#/utils/formatNumber';
import { fDate, fTime } from '#/utils/formatTime';
import Iconify from '#/components/shared/ui/Iconify';
import usePopover from '#/lib/hooks/usePopover';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import useLocales from '#/lib/hooks/useLocales';
import { FileAPI, FolderAPI } from '#/types/digitalAssets';
import IconButtonAnimate from '#/components/shared/ui/IconButtonAnimate';
import ConfirmDialog from '#/components/shared/confirm-dialog';
import FileManagerNewFolderDialog from '#/components/shared/file-manager/file-manager-new-folder-dialog';
import {
  invalidateDigitalAssetsQueries,
  useRenameFile,
  useRenameFolder,
  useToggleFavoriteFile,
  useToggleFavoriteFolder,
} from '#/lib/api/digitalAssets';

interface Row extends FolderAPI, FileAPI {}

type Props = {
  row: Row;
  selected: boolean;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onOpen?: VoidFunction;
  entityType: 'folder' | 'file';
  disableDrag?: boolean;
};

export default function FileTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onOpen,
  entityType,
  disableDrag,
}: Props) {
  const [newName, setNewName] = useState(
    row.name || row.filename.substring(0, row.filename.lastIndexOf('.'))
  );

  const theme = useTheme();

  const { name, filename, size, content_type, uploaded_at } = row;

  const { mutateAsync: updateFolder } = useRenameFolder(row.id);

  const { mutateAsync: updateFile } = useRenameFile(row.id);

  const { mutateAsync: toggleFavoriteFolder } = useToggleFavoriteFolder(row.id);
  const { mutateAsync: toggleFavoriteFile } = useToggleFavoriteFile(row.id);

  const handleFavorite = async () => {
    try {
      if (entityType === 'folder') {
        // @ts-ignore
        await toggleFavoriteFolder({ name: row.name });
        invalidateDigitalAssetsQueries.getFiles();
      }
      if (entityType === 'file') {
        // @ts-ignore
        await toggleFavoriteFile({ name: row.filename });
        invalidateDigitalAssetsQueries.getFiles();
      }
    } catch (error) {
      console.error('Error updating favorite: ');
    }
  };

  const {
    attributes,
    listeners,
    setNodeRef: setDraggableRef,
    transform,
    isDragging,
  } = useDraggable({
    id: row.id,
    data: { type: entityType, row },
    disabled: disableDrag,
  });

  const { isOver, setNodeRef: setDroppableRef } = useDroppable({
    id: row.id,
    disabled: entityType !== 'folder',
  });

  // Combine refs for draggable and droppable
  const setNodeRef = (node: HTMLElement | null) => {
    setDraggableRef(node);
    setDroppableRef(node);
  };

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    transition: 'transform 200ms ease',
    backgroundColor:
      isOver && entityType === 'folder'
        ? theme.palette.action.hover
        : 'inherit',
  };

  const { translate } = useLocales();

  const popover = usePopover();
  const confirm = useBoolean();
  const editFolder = useBoolean();
  const editFile = useBoolean();
  const favorite = useBoolean(row.starred);

  const handleClick = useDoubleClick({
    click: () => {
      onOpen?.();
    },
    doubleClick: () => console.info('DOUBLE CLICK'),
  });

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewName(event.target.value);
    },
    []
  );

  const handleFolderRename = async (name: string) => {
    try {
      await updateFolder({ name });
      invalidateDigitalAssetsQueries.getFiles();
      editFolder.onFalse();
    } catch (error) {
      console.error('Error updating folder: ');
    }
  };

  const handleFileRename = async (name: string) => {
    try {
      await updateFile({ name });
      invalidateDigitalAssetsQueries.getFiles();
      editFile.onFalse();
    } catch (error) {
      console.error('Error updating folder: ');
    }
  };

  return (
    <TableRow
      ref={setNodeRef}
      style={{
        ...style,
        borderRadius: 2,
        [`&.${tableRowClasses.selected}, &:hover`]: {
          backgroundColor: 'background.paper',
          boxShadow: theme.customShadows.z20,
          transition: theme.transitions.create(
            ['background-color', 'box-shadow'],
            {
              duration: theme.transitions.duration.shortest,
            }
          ),
          '&:hover': {
            backgroundColor: 'background.paper',
            boxShadow: theme.customShadows.z20,
          },
        },
        [`& .${tableCellClasses.root}`]: {},
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected}
          onDoubleClick={() => console.info('ON DOUBLE CLICK')}
          onClick={onSelectRow}
        />
      </TableCell>

      <TableCell padding="checkbox">
        {!disableDrag && (
          <IconButtonAnimate
            sx={{
              cursor: 'grab',
            }}
            {...listeners}
            {...attributes}
          >
            <Iconify icon="mdi:drag" />
          </IconButtonAnimate>
        )}
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          onClick={handleClick}
          sx={{
            cursor: 'pointer',
            width: 'fit-content',
          }}
        >
          <FileThumbnail
            file={content_type?.split('/')[1] ?? 'folder'}
            sx={{ width: 36, height: 36 }}
          />

          <Typography
            noWrap
            variant="inherit"
            sx={{
              maxWidth: 360,
            }}
          >
            {name || filename}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>{fData(size)}</TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {content_type?.split('/')[1].toUpperCase() ||
          translate('global.folder')}
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <ListItemText
          primary={fDate(uploaded_at)}
          secondary={fTime(uploaded_at)}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
      <TableCell
        align="right"
        sx={{
          px: 1,
          whiteSpace: 'nowrap',
        }}
      >
        <Checkbox
          color="warning"
          icon={<Iconify icon="eva:star-outline" />}
          checkedIcon={<Iconify icon="eva:star-fill" />}
          checked={row.starred || favorite.value}
          onChange={() => {
            handleFavorite();
            favorite.onToggle();
          }}
          sx={{ p: 0.75 }}
        />

        <IconButton
          color={popover.open ? 'inherit' : 'default'}
          onClick={popover.onOpen}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 160 }}
      >
        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
        <MenuItem
          onClick={() => {
            popover.onClose();
            if (entityType === 'folder') {
              editFolder.onTrue();
            }
            if (entityType === 'file') {
              editFile.onTrue();
            }
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {String(translate('global.edit'))}
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {translate('global.delete')}
        </MenuItem>
      </CustomPopover>
      {entityType === 'folder' && (
        <FileManagerNewFolderDialog
          open={editFolder.value}
          onClose={editFolder.onFalse}
          title={String(translate('global.editFolder'))}
          onUpdate={() => handleFolderRename(newName)}
          onChangeFolderName={handleNameChange}
          folderName={newName}
        />
      )}
      {entityType === 'file' && (
        <FileManagerNewFolderDialog
          open={editFile.value}
          onClose={editFile.onFalse}
          title={String(translate('global.editFile'))}
          onUpdate={() => handleFileRename(newName)}
          onChangeFolderName={handleNameChange}
          folderName={newName}
        />
      )}
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={String(translate('global.deleteConfirmation.title'))}
        content={String(
          translate('global.deleteConfirmation.question', {
            item: row.name || row.filename,
          })
        )}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              confirm.onFalse();
            }}
          >
            <Typography>{String(translate('global.delete'))}</Typography>
          </Button>
        }
      />
    </TableRow>
  );
}
